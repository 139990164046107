<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Métier" />
          </div>

          <div class="d-flex justify-center">
            <WarningEditComponent />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Détail du métier</div>
              </v-row>
            </v-card-title>

            <!-- le nom -->
            <InputFieldComponent label="nom">
              <template v-slot:value>
                <v-text-field
                  placeholder="Le nom du métier"
                  dense
                  v-model="name"
                  class="field-uppercase"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <!-- la marque / domaine -->
            <InputFieldComponent 
              label="marque métier / nom de domaine"
              :divider=false>
              <template v-slot:value>
                <v-autocomplete
                  placeholder="Sélectionner"
                  :items="domainBrands"
                  item-value="id"
                  no-data-text="aucune donnée"
                  v-model="domainBrandId"
                  clearable
                >
                </v-autocomplete>
              </template>
            </InputFieldComponent>
            
            <v-divider></v-divider>
              
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!hasChanged || !canSave"
                @click="save"
              >
                <div class="capitalize">
                  enregistrer
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- Fenetre modale pour éviter que l'utilisateur quitte sans sauvegarder -->
    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import WarningEditComponent from "@/components/ui/WarningEditComponent.vue";
import InputFieldComponent from "@/components/ui/InputFieldComponent.vue";

import { MetierService } from "@/service/sfr/metier_service.js";
import { DomainBrandService } from "@/service/sfr/domain_brand_service.js";

import { areTheSame } from "@/tools/string_tool.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "EditMetier",
  components: {
    Workflow,
    TitleAndReturnComponent,
    WarningEditComponent,
    AlertNotSavedModifsComponent,
    InputFieldComponent,
  },

  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès aux métiers. */
      serviceMetier: null,

      /**le service d'accès aux marques métier / nom de domaines. */
      serviceDominBrand: null,

      /** l'identifiant à éditer */
      metierId: null,

      /**La donnée originale (pour pouvoir faire une comparaison) */
      source: null,

      /**le nom du métier */
      name: null,

      /** la marque / domaine associée au métier */
      domainBrandId: null,

      /**la liste des marques domaines utilisables */
      domainBrands: [],
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        //on charge le métier à éditer
        let metier = await this.serviceMetier.getById(this.metierId);

        this.source = JSON.parse(JSON.stringify(metier));

        //on charge les marques/domaines à associer

        let items = await this.serviceDominBrand.getAll();

        //on rajoute un adaptateur pour l'affichage dans la comobobox
        items.forEach((item) => {
          item.text = item.brand + " / " + item.domain;
        });

        this.domainBrands = items;

        // console.log("items:" + JSON.stringify(this.domainBrands, null, 4));

        this.init();
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    init() {
      this.name = this.source.name;

      //on fait la liaison de donnée
      if (this.source.domainBrand) {
        this.domainBrandId = this.source.domainBrand.id;
      }
    },
    async save() {
      try {
        let resource = JSON.parse(JSON.stringify(this.source));
        resource.name = this.name.toUpperCase();
        resource.domainBrandId = this.domainBrandId;

        await this.serviceMetier.update(resource);

        this.disableAlertQuit();
        this.$router.go(-1);
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "enregistrement : " +
            (exceptions.toMessage(error) || "problème technique")
        );
      }
    },
  },
  computed: {
    hasChanged() {
      if (!this.source) return false;

      if (!areTheSame(this.source.name, this.name)) {
        return true;
      }

      let domainBrandIdSource = null;

      //on vérifie si le métier a une marque métier
      if (this.source.domainBrand) {
        domainBrandIdSource = this.source.domainBrand.id;
      }

      //on vérifie si la marque métier a changé
      if (this.domainBrandId !== domainBrandIdSource) {
        return true;
      }

      return false;
    },
    canSave() {
      //on peut enregistrer s'il y a un nom de métier et une marque métier associée
      return this.name && this.domainBrandId;
    },
  },
  mounted() {
    this.serviceMetier = new MetierService(this.$api.getMetierApi());

    this.serviceDominBrand = new DomainBrandService(
      this.$api.getDomainBrandsApi()
    );

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.metierId = this.$route.params.id;
    }

    this.load();
  },
};
</script>

<style>
</style>